<template>
    <div class="card">
        <div class="card-header">
            <h5>{{title}}</h5>
        </div>
        <div class="card-body">
            <h5 class="text-start">New Contact</h5>
            <form >
            <div class="row">
                <div class="col">
                    <div class="input-group">
                        <span class="input-group-text" >Name</span>
                        <input type="text" class="form-control" v-model="curContact.name" placeholder="name">
                    </div>
                </div>
                <div class="col">
                    <div class="input-group">
                        <span class="input-group-text" > Designation</span>
                        <input type="text" class="form-control" v-model="curContact.designation" placeholder="Designation">
                    </div>
                </div>
            </div>
            <div class="row mt-2">
                <div class="col">
                    <div class="input-group col-6">
                        <span class="input-group-text" > Phone</span>
                        <input type="number" class="form-control" v-model="curContact.phoneno"  placeholder="Phone">
                    </div>
                </div>
                <div class="col">
                    <div class="input-group col-6">
                        <span class="input-group-text" > Email</span>
                        <input type="email" class="form-control" v-model="curContact.email" placeholder="Email">
                    </div>
                </div>
            </div>
            </form>
        </div>
        <div class="card-footer">
            <button v-if="isDelete == false" class="btn  btn-primary btn-sm ms-2" v-on:click="saveRecord">Save</button>
            <button v-if="isDelete == true" class="btn  btn-primary btn-sm ms-2" v-on:click="deleteRecord">Delete</button>
            <button class="ps-2 btn  btn-primary btn-sm ms-2" v-on:click="cancel">Close</button>
        </div>
    </div>
</template>

<script>
export default {
    name:"newContactComponent",
    props: {
        title: { type :String },
        isDelete : { type : Boolean, default : false},
        contact: { type: Object,
                    default: function () {return {}}
                 },

    },
    emits:['saveRecord','cancelRecord','deleteRecord'],
    data(){
      return {
          curContact:{name:"",designation:"",phoneno:"",email:""}
      }
    },
    created(){
        if(this.isDelete==true){
            this.curContact.name= this.contact.name;
            this.curContact.designation = this.contact.designation;
            this.curContact.phoneno = this.contact.phoneno;
            this.curContact.email = this.contact.email;
        }
    },
    methods:{
        saveRecord:function(){
            this.$emit('saveRecord',this.curContact);
        },
        cancel:function(){
            this.$emit('cancelRecord');
        },
        deleteRecord :function(){
            this.$emit('deleteRecord',this.contact);
        }
    }
}
</script>