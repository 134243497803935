<template>
  <div>
      <div class=" m-2 pt-2 pb-2 bg-secondary text-light">Vendor Management</div>
      <div class="row">
          <div class="col">
              <div class="input-group">
                <div class="w-50"><input type="text" class="form-control form-control-sm" placeholder="Enter vendor name" aria-label="City"></div>
                <button class="btn btn-outline-primary btn-sm ms-2 " v-on:click="listVendors">Get</button>
                <button class="btn btn-outline-primary btn-sm ms-2"  v-on:click="newVendor">New Vendor</button>
              </div>
          </div>
      </div>
      <div class="row">
          <div v-if="showscreen==screens.SEARCH_RESULTS" class="mt-2"> 
             <div class="table-responsive">
             <table class="table table-striped table-hover caption-top">
                 <caption>Search Reasults</caption>
                 <thead>
                     <tr>
                         <th class="w-25">Name</th>
                         <th class="w-50">Address</th>
                         <th class="w-25"></th>
                     </tr>
                 </thead>
                 <tbody>
                     <tr class="text-start" v-for="(vendor) in vendors" v-bind:key="vendor.id">
                         <td>{{vendor.name}}</td>
                         <td>{{vendor.address}}</td>
                         <td>
                            <div class="col">
                            <button type="button" class="ms-2 btn btn-sm btn-outline-primary" v-on:click="showDetails(vendor)"><i class="bi bi-card-text"></i></button>
                            <button type="button" class="ms-2 btn btn-sm btn-outline-primary"><i class="bi bi-pencil-square"></i></button>
                            </div>
                         </td>
                     </tr>
                 </tbody>
             </table>
             </div>

          </div>
          <div v-if="showscreen==screens.NEW_VENDOR" class="mt-2">
             <div class="card">
                 <div class="card-header">
                     <h3>New Vendor</h3>
                 </div>
                 <div class="card-body">
                     <form >
                        <div class="row">
                            <div class="col">
                                <div class="input-group">
                                    <span class="input-group-text" > Name</span>
                                    <input type="text" class="form-control" placeholder="name">
                                </div>
                            </div>
                            <div class="col">
                                <div class="input-group col-6">
                                    <span class="input-group-text" > GST Number</span>
                                    <input type="number" class="form-control" placeholder="GST Number">
                                </div>
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div class="col">
                                <div class="col-6">
                                    <div class="input-group">
                                        <span class="input-group-text"> Address</span>
                                        <textarea class="form-control" rows="5" ></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                     </form>
                 </div>
                 <div class="card-footer"> 
                     <button class="btn  btn-primary btn-sm ms-2">Save</button>
                 </div>
             </div>
          </div>
          <div v-if="showscreen==screens.VENDOR_DETAILS" class="mt-2">
             <div class="card">
                 <div class="card-header">
                     <h3>Vendor Details</h3>
                 </div>
                 <div class="card-body">
                     <form >
                        <div class="row">
                            <div class="col">
                                <div class="input-group">
                                    <span class="input-group-text" >Name</span>
                                    <input type="text" class="form-control" v-model="curVendor.name" placeholder="name">
                                </div>
                            </div>
                            <div class="col">
                                <div class="input-group">
                                    <span class="input-group-text" > Address</span>
                                    <input type="text" class="form-control" v-model="curVendor.address" placeholder="Address">
                                </div>
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div class="col">
                                <div class="table-responsive">
                                    <table class="table caption-top table-sm">
                                        <caption>Contacts</caption>
                                        <thead>
                                            <tr>
                                                <th>Name</th>
                                                <td>Designation</td>
                                                <td>Phone</td>
                                                <td>Email</td>
                                                <td></td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr class="text-start" v-for="contact in curVendor.contacts" v-bind:key="contact.id">
                                                <td>{{contact.name}}</td>
                                                <td>{{contact.designation}}</td>
                                                <td>{{contact.phoneno}}</td>
                                                <td>{{contact.email}}</td>
                                                <td><button class="btn btn-outline-primary btn-sm" v-on:click="addContactToVendor"><i class="bi bi-person-dash"></i></button></td>
                                            </tr>
                                        </tbody>
                                        <tfoot >
                                            <button v-on:click="addContactToVendor" class="btn  btn-primary btn-sm ms-2 mt-2"><i class="bi bi-person-plus"></i></button>
                                        </tfoot>
                                    </table>
                                </div>
                            </div>
                            <div class="col">
                                <div class="table-responsive">
                                    <!--
                                    <table class="table table-striped table-hover caption-top">
                                        <caption>Products</caption>
                                        <thead>
                                            <tr>
                                                <th>Product Name</th>
                                                <td></td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr class="text-start" v-for="product in curVendor.products" v-bind:key="product.id">
                                                <td>{{product.productName}}</td>
                                                <td><button class="btn btn-outline-primary btn-sm "><i class="bi bi-trash"></i></button></td>
                                            </tr>
                                        </tbody>
                                        <tfoot>
                                            <button v-on:click="addProductToVendor" class="btn  btn-primary btn-sm ms-2 mt-2"><i class="bi bi-plus-lg"></i><i class="bi bi-box-seam"></i></button>
                                        </tfoot>
                                    </table>
                                    -->
                                </div>
                            </div>
                        </div>
                     </form>
                 </div>
                 <div class="card-footer"> 
                     <button class="btn  btn-primary btn-sm ms-2">Save</button>
                     <button class="ps-2 btn  btn-primary btn-sm ms-2" v-on:click="showListScreen">Close</button>
                 </div>
             </div>
          </div>
          <div v-if="showscreen==screens.ADD_CONTACT" class="mt-2">
              <newContactComponent @save-record="contactSavedEventHandler" @cancel="cancelEventHandler" v-bind:title="'Vendor Contact Management'"> </newContactComponent>
          </div>
          <div v-if="showscreen==screens.ADD_PRODUCT" class="mt-2">
              <div class="card">
                 <div class="card-header">
                     <h3>{{curVendor.name}}</h3>
                 </div>
                 <div class="card-body">
                     <h5 class="text-start">Choose Product to supply</h5>
                     <form>
                         <div class="row">
                            <div class="col">
                                <div class="input-group">
                                    <span class="input-group-text" >Product</span>
                                    <select class="form-select">
                                        <option v-for="product in products" v-bind:key="product.id">
                                            {{product.pname}}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="col">
                                <div class="input-group">
                                    
                                </div>
                            </div>
                        </div>
                     </form>
                 </div>
                 <div class="card-footer">
                     <button class="btn  btn-primary btn-sm ms-2">Save</button>
                     <button class="ps-2 btn  btn-primary btn-sm ms-2" v-on:click="showDetails(curVendor)">Close</button>
                 </div>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
// @ is an alias to /src
import newContactComponent from '@/components/newContact.vue'

export default {
  name: 'VendorMgmt',
  components:{
      newContactComponent
  },
  data(){
      return {
          showscreen:0,
          vendors:[],
          products:[],
          curVendor:{},
          screens:{SEARCH_RESULTS:1,NEW_PRODUCT:2,VENDOR_DETAILS:3,ADD_CONTACT:4,ADD_PRODUCT:5}
      }
  },
  created(){
      this.vendors=[
          {id:"1",name:"Bangalore IT",address:"No 43/1, Mask Road, Frazer Town, Bengaluru-560005, Karnataka, India",contacts:[{id:"12",name:"Dhanu",phoneno:"9845012345",designation:"Manager",email:"dhanu@bangaloreit.com"},{id:"13",name:"Dev",phoneno:"9845054321",designation:"Regional Manager",email:"dev@bangaloreit.com"}],
                                    products:[{id:"12",productName:"Inspiron 15 Laptop"},{id:"13",productName:"Inspiron 14 Laptop 8GB"}]},
          {id:"2",name:"ARIHANT DISTRIBUTORS",address:"93/2 , Behind Sharada Talkies, SP Rd, Bangalore",contacts:[{id:"14",name:"Raj",phoneno:"9731398226",designation:"executive",email:""}],
                                    products:[{id:"12",productName:"Inspiron 15 3000 laptop"},{id:"13",productName:"HP 34W78PA"}]},
          {id:"3",name:"Manas Merchandise Pvt Ltd",address:" Khans , No 13 (27/1) , 1st Floor , Serpentine Street , Near Asm Technologies , Richmond Town,Bangalore , Karnataka , 560025",contacts:[{id:"15",name:"helpdesk",phoneno:"9880989654",designation:"manager",email:"info@gmail.com"}],
                                    products:[{id:"12",productName:"HP LaserJet Pro M126nw"}]}
      ];
       this.products=[
          {id:"1",pname:"Inspiron 15 Laptop",pcat:"LAPTOP",partno:"icc-c783511win8",hsn:"84713010",gst:"18",mrp:"79,989.99",desc:""},
          {id:"2",pname:"Inspiron 14 Laptop 8GB",pcat:"LAPTOP",partno:"icc-c782512win8",hsn:"84713010",gst:"18",mrp:"72,489.98",desc:""},
          {id:"3",pname:"Inspiron 14 Laptop 16GB",pcat:"LAPTOP",partno:"icc-c782519win8",hsn:"84713010",gst:"18",mrp:"66,989.99",desc:""},
          {id:"4",pname:"Inspiron 15 3000 laptop",pcat:"LAPTOP",partno:"d560509win9s",hsn:"84713010",gst:"18",mrp:"46,489.98",desc:""},
          {id:"5",pname:"HP 34W78PA",pcat:"LAPTOP",partno:"15s-fr2006TU",hsn:"84713010",gst:"18",mrp:"46,499",desc:"11th Generation Intel® Core™ i3 processor"},
          {id:"6",pname:"HP LaserJet Pro M126nw",pcat:"PRINTER",partno:"235991",hsn:"84713746",gst:"18",mrp:"21,329.00",desc:"HP LaserJet Pro M126nw Wireless Black & White Multi-Function Laserjet Printer (Wireless Direct Printing, CZ175A, Black)"},
      ];
  },
  methods:{
      listVendors:function(){
          this.showscreen= this.screens.SEARCH_RESULTS;
      },
      newVendor:function(){
          this.showscreen=this.screens.NEW_VENDOR;
      },
      showDetails:function(vendor){
          this.curVendor=vendor;
          this.showscreen=this.screens.VENDOR_DETAILS;

      },
      showListScreen:function(){
          this.showscreen=this.screens.SEARCH_RESULTS;
      },
      addContactToVendor:function(){
          this.showscreen=this.screens.ADD_CONTACT;
      },
      addProductToVendor:function(){
          this.showscreen=this.screens.ADD_PRODUCT;
      },
      contactSavedEventHandler:function(){

      },
      cancelEventHandler:function(){
          this.showscreen=this.screens.VENDOR_DETAILS;
      }
  }
}
</script>
